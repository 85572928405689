<template>
    <div
        class="modal fade show"
        id="searchTagMacAddrByLabelModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        data-backdrop="static"
    >
        <div class="modal-dialog modalHelpPlacement" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("act_searchAutoCalibrationTagByLabel") }}</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group validated">
                        <label for="searchTagMcAddrLabelInput">{{ $t("common_label") }} *</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="la la-pencil"></i>
                                </span>
                            </div>
                            <input
                                v-model="vLabel"
                                @input="$v.vLabel.$touch()"
                                type="text"
                                class="form-control"
                                id="searchTagMcAddrLabelInput"
                                :placeholder="$t('common_enterLabel')"
                            />
                        </div>
                        <div
                            v-if="!$v.vLabel.required"
                            class="invalid-feedback"
                        >{{ $t("error_fieldIsRequired") }}</div>
                        <div
                            v-else-if="!$v.vLabel.minLen"
                            class="invalid-feedback"
                        >{{ $t("error_minLengthMsg", [$v.vLabel.$params.minLen.min]) }}</div>
                        <span
                            class="form-text text-muted"
                        >{{ $t("act_searchAutoCalibrationTagByLabelExample") }}</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        id="searchTagMcAddrLabelSearchButton"
                        type="button"
                        @click="onSearch"
                        :disabled="$v.$invalid"
                        class="btn btn-brand"
                    >{{ $t("common_search") }}</button>
                    <button
                        id="searchTagMcAddrLabelCloseButton"
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >{{ $t("common_close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "searchtagmacaddrbylabelmodal",
    data() {
        console.log("Component(searchTagMacAddrByLabelModal)::data() - called");
        return {
            vLabel: ""
        };
    },
    created: function() {
        console.log(
            "Component(searchTagMacAddrByLabelModal)::created() - called"
        );
    },
    mounted: function() {
        console.log(
            "Component(searchTagMacAddrByLabelModal)::mounted() - Init metronic layout"
        );
    },
    destroyed: function() {
        console.log(
            "Component(searchTagMacAddrByLabelModal)::destroyed() - called"
        );
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vLabel: {
            required,
            minLen: minLength(2)
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentShippedTag: function(tag) {
            console.log(
                "Component(searchTagMacAddrByLabelModal)::wath(currentShippedTag) - called with : ",
                tag
            );
            if (tag) {
                $("#searchTagMacAddrByLabelModal").modal("hide");
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentShippedTag"])
    },
    methods: {
        ...mapActions(["getShippedTagByLabel"]),
        onSearch() {
            console.log(
                "Component(searchTagMacAddrByLabelModal)::onSearch - tag label : ",
                this.vLabel
            );
            this.getShippedTagByLabel(this.vLabel);
        }
    }
};
</script>
